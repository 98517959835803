import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from '@reach/router'

import {
  currentServiceOrderSelector,
  getButtonsActionByNameSelector,
} from '_modules/service-orders/selectors'
import { getStepStatusLogSelector } from '_modules/budget/selectors'
import { userSelector } from '_/modules/authentication/selectors'
import { getButtonsAction } from '_/modules/service-orders/actions'
import { getStepStatusLog } from '_/modules/budget/actions'

// eslint-disable-next-line no-unused-vars
const useCanDoButtonAction = ({ nameButton, isBudgetRelated = false }) => {
  const { budgetId } = useParams()
  const user = useSelector(userSelector)?.toJS()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const buttonAction = useSelector(getButtonsActionByNameSelector(nameButton))
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (stepStatusLog.length === 0 && serviceOrder?.id && budgetId) {
      dispatch(getStepStatusLog(serviceOrder?.id, budgetId))
    }

    if (!buttonAction) {
      dispatch(getButtonsAction())
    }
  }, [budgetId, serviceOrder, buttonAction])

  const lastStepStatusLogWithBudget = useMemo(
    () => stepStatusLog.filter(log => budgetId === log.budgetId?.toString())[0],
    [serviceOrder?.activeBudget, stepStatusLog, budgetId]
  )

  const allowedStepStatus = useMemo(
    () => buttonAction?.optionsStepServiceOrder,
    [buttonAction?.optionsStepServiceOrder]
  )

  const canDoButtonActionNew = useMemo(() => {
    if (user.getRoles === 'tradesman') {
      if (
        stepStatusLog[0]?.tradesmanId === user.getServiceProviderId ||
        serviceOrder?.tradesmanInPoolCandidates
      ) {
        if (budgetId) {
          const isRouteBudgetEqualToSteplogBudget =
            stepStatusLog[0]?.budgetId?.toString() === budgetId

          if (isRouteBudgetEqualToSteplogBudget || !stepStatusLog[0]?.budgetId) {
            return allowedStepStatus?.some(
              stepStatus => stepStatus.name === stepStatusLog[0]?.stepStatus
            )
          }
        }

        return allowedStepStatus?.some(
          stepStatus => stepStatus.name === stepStatusLog[0]?.stepStatus
        )
      }

      return false
    }

    if (budgetId) {
      return (
        allowedStepStatus?.some(
          stepStatus => stepStatus.name === lastStepStatusLogWithBudget?.stepStatus
        ) || false
      )
    }

    return (
      allowedStepStatus?.some(stepStatus => stepStatus.name === stepStatusLog[0]?.stepStatus) ||
      false
    )
  }, [stepStatusLog, buttonAction, lastStepStatusLogWithBudget, budgetId, user, serviceOrder])

  return canDoButtonActionNew
}

export default useCanDoButtonAction
